import React from 'react'
import "../Navbar/Navbar.css";
import main_banner_video from "../../video/main_banner_video.mp4";
import main_bg_banner_video from "../../video/knightingale_landingpage_main_banner.mp4";
import knightingale_main_banner_mobile from "../../video/knightingale_landingpage_main_banner_mobile.mp4";
import knightingale_logo from "../../img/knightingale_logo.png";
import menu from "../../img/menu.svg";
import knightingale_agents_main_banner from "../../img/Navabar/knightingale_agents_main_banner.png";
import knightingale_main_banner_mobile_thumbnail from "../../img/Navabar/knightingale_main_banner_mobile_thumbnail.jpg";
import knightingale_landingpage_main_banner_thumbnail from "../../img/Navabar/knightingale_landingpage_main_banner_thumbnail.jpg";
import meet_me_on_whatsapp_button from "../../img/Navabar/meet_me_on_whatsapp_button.png";
import heart_icon from "../../img/Navabar/heart_icon.png";
import speech_balloon from "../../img/Navabar/speech-balloon.png";
import VideoCanvas from '../VideoCanvas/VideoCanvas';
import WhatsappButton from '../WhatsappButton/WhatsappButton';
import { Link } from 'react-router-dom';

const MainBanner = () => {
  const handleClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSejhBcGyhl3O7adKyeOxXgPKKp01oqeJ2nVaknv5T5KemlSwQ/viewform?usp=sf_link', '_blank');
  };

  const askClick = () => {
    window.open('https://ask.knightingale.ai/', '_blank');
  };

  return (
    <>
      <div className="main-banner-wrapper">
        <div className="main-banner-container">
          <div className='video-container'>
          <video className='desktop-video' poster={knightingale_landingpage_main_banner_thumbnail} src={main_bg_banner_video} playsInline loop autoPlay muted />
          <video  className='mobile-video' poster={knightingale_main_banner_mobile_thumbnail} src={knightingale_main_banner_mobile} playsInline loop autoPlay muted />
          </div>
          <div className="main-banner-inner">
            <div className="banner-container banner-container-left-side">
              <div className="banner-inner  banner-container-left">
                {/* <div className="banner-header">Hello, I’m Knightingale,</div> */}
                <div className="banner-header-2">A Healthier, Happier You</div>

                <div className="banner-title">Starting Today!</div>
                <div className=' banner-text1'>
                  <div className="banner-text">
                    <span style={{
                      fontWeight: 400, color: '#e4ecf3', lineHeight: '30px', textWrap: 'wrap',
                      fontFamily: 'Roboto'
                    }}>
                      I'm Knightingale, Your Personal <span> </span>
                      <span style={{ fontWeight: 500, color: '#ffffff' }}>
                        Healthy Lifestyle Companion
                      </span>
                      <span> </span>On Your Journey to a Wholesome Life. 💙
                      {/* <img
                        style={{
                          width: 30,
                          height: 30,
                          marginBottom: -8,
                          marginLeft: 5,
                          verticalAlign: 'middle' // Aligns the image with the text
                        }}
                        src={heart_icon}
                        alt='heart_icon'
                      /> */}
                    </span>
                  </div>

                </div>
                <div className="banner-wrapper-btn">
                  <div className="banner-inner-btn">
                    {/* <button className="banner-btn banner-btn-2" onClick={askClick} >Ask Knightingale</button> */}
                    {/* <button className="banner-btn  banner-btn-1" onClick={handleClick}><img style={{ width: "100%", height: "" }} src={meet_me_on_whatsapp_button} alt='meet_me_on_whatsapp_button' /></button> */}
                    <WhatsappButton />
                    {/* <Link to={"/ContactPage"} >ContactPage</Link> */}
                  </div>
                </div>
                <div className="banner-text2">
                  <span style={{ fontWeight: 400, color: '#D5E3F0', textWrap: 'wrap',
                      fontFamily: 'Roboto', fontSize: 14, lineHeight: '16px' }}> 
                        * Knightingale provides wellness support, not medical advice. For health concerns, please consult a healthcare professional.</span>
                </div>
              </div>
            </div>
            <div className="banner-container  banner-container-right-side">
              <div className="banner-inner  banner-container-right">
                <div className="banner-inner-img">
                  {/* <video width="100%" height="100%" style={{ objectFit: 'cover' }} poster={knightingale_main_banner_mobile_thumbnail} src={knightingale_landingpage_main_banner_mobile} playsinline loop autoPlay muted /> */}

                  {/* <img style={{ width: "100%", height: "100%" }} src={knightingale_agents_main_banner} alt='' /> */}
                  {/* <VideoCanvas /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainBanner